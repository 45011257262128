
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faPlay, faTimes} from "@fortawesome/free-solid-svg-icons";


export const getRoomIconByStatus = (status) => {
    switch (status) {
        case "approved":
            return <FontAwesomeIcon icon={faCheck} className="text-green-500"/>
        case "started":
            return <FontAwesomeIcon icon={faPlay} />
        case "declined":
            return <FontAwesomeIcon className=" text-red-500" icon={faTimes}/>
        default:
            return <FontAwesomeIcon icon={faClock}/>
    }
}

export const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

export const getClassByStatus = (status) => {
    switch (status) {
        case "approved":
            return 'bg-green-200'
        case "declined":
            return ''
        case "warning":
            return 'bg-red-200'
        default:
            return 'bg-amber-200'
    }
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const getDateByMonthAndHour = (date, hour) => {
    return date.substr(0, 10) + "T" + hour + ":00.000Z"
}

export const popUp = (url) => {
     let popup;
     if(window.innerWidth < 700){
        console.log(1)
        popup = window.open(url, "_self")
     } else{
        window.open(url, "_blank")
     }
     return popup !== null && typeof popup !== 'undefined'
}